<template>
  <div class="w-100">
    <div v-if="setupComplete"
         id="main-container-080720210908"
    >
      <div class="row w-100">
        <!-- Link to conversation history -->
        <div class="d-flex justify-content-end pb-3">
          <b-btn :to="{path: conversationListUrl, query: $route.query}"
                 variant="light"
                 class="px-4"
          >
            {{ staticText.conversationsButtonLabel }}
            <i class="pl-3 fas fa-chevron-right"></i>
          </b-btn>
        </div>
      </div>

      <div class="row overflow-hidden">
        <!-- Left Container - Filter -->
        <div class="col-3">
          <!-- Filter header -->
          <div class="d-flex justify-content-between mb-2">
            <div class="subTitle my-auto">
              {{ staticText.filterTitle }}
            </div>

            <b-btn :disabled="!filtersExist"
                   variant="secondary"
                   class="px-4"
                   @click="resetFilter"
            >
              <img src="/img/icons/restore-icon.svg"
                   class="pb-1"
              />
              {{ staticText.resetAll }}
            </b-btn>
          </div>
          <!-- Date Filter -->
          <div class="mb-2 d-flex mt-1 mb-1">
            <VueCtkDateTimePicker
              id="date-filter-202012281452"
              v-model="dateFilter"
              :max-date="new Date().toISOString()"
              format="YYYY-MM-DDTHH:mm:ss.sssZ"
              range
            />
          </div>

          <!-- User Filter -->
          <div class="mb-2">
            <base-selection
              id="filter-for-user-202012281452"
              v-model="userFilter"
              :show-labels="false"
              :placeholder="staticText.selectUserPlaceholder"
              track-by="email"
              label="full_name"
              url="/api/users/get_group_members?is_active=true"
            ></base-selection>
          </div>

          <!-- Counterpart Filter -->
          <div class="mb-2">
            <base-selection
              id="filter-for-counterpart-202012281452"
              v-model="counterpartFilter"
              :show-labels="false"
              :placeholder="staticText.selectCounterpartPlaceholder"
              url="/api/callstatistics/get_counterparts_list"
              :query="$route.query"
            ></base-selection>
          </div>

          <!-- Playbook Filter Input -->
          <div class="mb-2">
            <b-form-input id="filter-for-playbook-202012281452"
                          v-model="searchFilter"
                          :placeholder="staticText.filterPlaybookLabel"
            ></b-form-input>
          </div>

          <!-- Delete and Reset Filter controls -->
          <div>
            <div class="mb-2 d-flex justify-content-between align-items-center current-answer"
                 v-if="!!$route.query.answers"
            >

              <div>
                <div class="current-answer-title">Current answer: </div>
                <div class="current-answer-result">{{ $route.query.answers }}</div>
              </div>
              <b-btn variant="light" class="trash-answer-btn"
                     @click="setFilter({answers: null})"
              >
                <i class="fas fa-trash"></i>
              </b-btn>

            </div>
            <div class="mb-2 d-flex justify-content-between align-items-center current-answer"
                 v-if="!!$route.query.objections"
            >
              <div>
                <div class="current-answer-title">Current objection: </div>
                <div class="current-answer-result">{{ $route.query.objections }}</div>
              </div>
              <b-btn variant="light" class="trash-answer-btn"
                     @click="setFilter({objections: null})"
              >
                <i class="fas fa-trash"></i>
              </b-btn>
            </div>
          </div>

          <!-- Navigation Menu -->
          <div>
            <!-- Calls -->
            <div class="my-1 navigation-menu">
              <div class="d-flex mb-2 justify-content-between">
                <b-btn class="border-0 filter-button"
                       @click="setFilter({category: 'calls', timeframe: 'days', type: null})"
                >
                  <i v-if="checkFilter(defaults.callsFilter)"
                     class="fa fa-minus filter-button-closed-icon"
                  ></i>

                  <i v-else
                     class="fa fa-plus filter-button-opened-icon"
                  ></i>

                  <span class="ml-2">
                    {{ staticText.conversationsLabel }}
                  </span>
                </b-btn>

                <b-spinner v-if="isLoading('loadingCalls')"
                           class="orange-color m-4"
                ></b-spinner>

                <b-badge v-else
                         variant="secondary"
                         class="my-auto mr-4"
                         pill
                >
                  {{ callCount }}
                </b-badge>
              </div>

              <div v-if="checkFilter(defaults.callsFilter)">
                <b-collapse id="collapse-calls-time-202012290843"
                            :visible="true"
                            class="mb-2"
                >
                  <div class="border-top mx-4"></div>
                  <b-btn v-for="(value, key) in timeframeSelection"
                         :key="key"
                         class="border-0 filter-button p-2"
                         size="sm"
                         @click="showCallChart({timeframe: key})"
                  >

                    <i v-if="checkFilter({timeframe: key})"
                       class="fas fa-check-circle"
                    ></i>

                    <i v-else
                       class="far fa-check-circle"
                    ></i>

                    <span class="ml-2 h6">
                      {{ value }}
                    </span>
                  </b-btn>
                </b-collapse>
              </div>
            </div>

            <!-- Playbooks -->
            <div class="my-1 navigation-menu">
              <div class="d-flex mb-2 justify-content-between">

                <b-btn
                  class="border-0 filter-button"
                  @click="setFilter(defaults.playbookFilter)"
                >

                  <i v-if="checkFilter(defaults.playbookFilter)"
                     class="fas fa-minus filter-button-closed-icon"
                  ></i>

                  <i v-else
                     class="fas fa-plus filter-button-opened-icon"
                  ></i>

                  <span class="ml-2">
                    {{ staticText.playbookLabel }}
                  </span>
                </b-btn>

                <b-spinner v-if="isLoading('loadingPlaybooks')"
                           class="orange-color m-4"
                ></b-spinner>
              </div>

              <b-collapse id="collapse-answers-202012290848"
                          :visible="checkFilter(defaults.playbookFilter)"
                          class="mt-2"
              >
                <div class="border-top mx-4"></div>
                <div v-for="(playbook, index) in playbookOptions"
                     :key="index"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <b-btn
                      class="border-0 w-100 d-flex justify-content-between align-items-baseline filter-button text-left"
                      @click="selectPlaybook(playbook, index)"
                    >
                      <div class="analytics-item-selector">
                        <i v-if="checkFilter({playbook: playbook.id})"
                           class="fas fa-circle fa-xs radio-button-on"
                        ></i>

                        <i v-else
                           class="fas fa-circle fa-1x radio-button-off"
                        ></i>

                        <span class="ml-1">
                          {{ playbook.name }}
                        </span>
                      </div>

                      <b-badge
                        :variant="getVariant({playbook: playbook.id}, 'primary', 'secondary')"
                        class="ml-2 mr-4"
                        pill
                      >
                        {{ playbook.call_count }}
                      </b-badge>
                    </b-btn>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- Shortcuts -->
            <div class="my-1 navigation-menu">
              <div class="d-flex mb-2 justify-content-between">
                <b-btn
                  class="border-0 filter-button"
                  @click="setFilter(defaults.objectionFilter)"
                >

                  <i v-if="checkFilter(defaults.objectionFilter)"
                     class="fas fa-minus filter-button-closed-icon"
                  ></i>

                  <i v-else
                     class="fas fa-plus filter-button-opened-icon"
                  ></i>

                  <span class="ml-2">
                    {{ staticText.shortcutLabel }}
                  </span>
                </b-btn>

                <b-spinner v-if="isLoading('loadingPlaybooks')"
                           class="orange-color m-4"
                ></b-spinner>
              </div>

              <b-collapse id="collapse-answers-202012290858"
                          :visible="checkFilter(defaults.objectionFilter)"
                          class="mt-2"
              >
                <div class="border-top mx-4"></div>
                <div v-for="(playbook, index) in objectionOptions"
                     :key="index"
                     class="ml-1"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <b-btn
                      class="border-0 w-100 d-flex justify-content-between align-items-baseline filter-button"
                      @click="selectPlaybook(playbook, index)"
                    >

                      <div class="analytics-item-selector">
                        <i v-if="checkFilter({playbook: playbook.id})"
                           class="fas fa-circle radio-button-on"
                        ></i>

                        <i v-else
                           class="fas fa-circle radio-button-off"
                        ></i>

                        <span class="ml-1">
                          {{ playbook.name }}
                        </span>
                      </div>

                      <b-badge
                        :variant="getVariant({playbook: playbook.id}, 'primary', 'secondary')"
                        class="ml-2 mr-4"
                        pill
                      >
                        {{ playbook.call_count }}
                      </b-badge>
                    </b-btn>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>

        <!-- Right - Chart & Data -->
        <div class="col-9">
          <div class="overflow-x-auto">
            <!-- Playbook based Reporting -->
            <div v-if="showPlaybookOverviewSummary">
              <playbook-overview-summary :playbook="selectedPlaybook"></playbook-overview-summary>
            </div>

            <!-- Time-based call chart -->
            <div v-else>
              <bao-analytics-calls-chart
                :data-to-display="dataToDisplay"
                :chart-display-style="chartDisplayStyle"
                :loading="loading"
                :tableLabel="tableLabel()"
                :show-filter-btn="!checkFilter({category:'calls'})"
                :chart-title="chartTitle"
                @set-filter="answerAsFilter"
              ></bao-analytics-calls-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters, mapMutations } from "vuex"
import { dateFormatingOptions } from "./index.js"
import BaoAnalyticsCallsChart from "./BaoAnalyticsComponents/BaoAnalyticsCallsChart"
import PlaybookOverviewSummary from "./BaoAnalyticsComponents/BaoAnalyticsPlaybookSummary"
import BaseSelection from "../base/BaseSelection"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"

export default {
  name: "BaoAnalytics",
  components: {
    PlaybookOverviewSummary,
    BaoAnalyticsCallsChart,
    BaseSelection,
    VueCtkDateTimePicker: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "VueCtkDateTimePicker" */ "vue-ctk-date-time-picker")
  },
  data () {
    return {
      axios,
      basePlaybookUrl: "/api/talkscriptstatistics/",
      baseCallUrl: "/api/callstatistics",
      conversationListUrl: "/calls",
      dateFilter: {},
      defaults: {
        callsFilter: { category: "calls" },
        timeframeFilter: { timeframe: ["days", "weeks", "months"] },
        playbookFilter: { category: "playbooks", timeframe: null },
        objectionFilter: { category: "objection", timeframe: null },
        answerFilter: { category: "answers", timeframe: null }
      },
      timeframe: null,
      filter: null,
      chartTitle: null,
      dataToDisplay: [],
      labelCol: "Date",
      sortDesc: true,
      sortDirection: "desc",
      sortBy: "count",
      userFilter: null,
      counterpartFilter: null,
      usersList: [],
      counterpartsList: [],
      selectedPlaybook: {},
      searchFilter: null,
      loading: [],
      calls: [],
      callCountFilter: 0,
      playbooks: [],
      playbooksWithAnswers: [],
      playbooksWithObjections: [],
      staticTextDefault: {
        filterTitle: "Filter",
        selectUserPlaceholder: "Select a User",
        selectCounterpartPlaceholder: "Select a Counterpart",
        filterPlaybookLabel: "Filter Playbooks",
        dashboardLabel: "Analytics",
        conversationsLabel: "Conversations",
        playbookLabel: "Playbooks",
        shortcutLabel: "Shortcuts",
        dateLabel: "Date",
        daysLabel: "Days",
        weeksLabel: "Weeks",
        monthsLabel: "Months",
        conversationsButtonLabel: "Show Conversations",
        resetAll: "Reset All"
      },
      dateFormatingOptions,
      setupComplete: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    chartDisplayStyle () {
      const timeframe = this.$route.query.timeframe
      return !timeframe ? "total" : timeframe
    },
    url_filter () {
      return { ...this.$route.query }
    },
    playbookOptions () {
      const result = this.playbooks.filter(
        item => item.call_count >= this.callCountFilter && item.type === "default"
      )
      if (!this.searchFilter) return result
      return result.filter(item => item.name.indexOf(this.searchFilter) >= 0)
    },
    objectionOptions () {
      const result = this.playbooks.filter(item => item.call_count >= this.callCountFilter && item.type === "objection")
      if (!this.searchFilter) return result
      return result.filter(item => item.name.indexOf(this.searchFilter) >= 0)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    timeframeSelection () {
      return {
        days: this.staticText.daysLabel,
        weeks: this.staticText.weeksLabel,
        months: this.staticText.monthsLabel
      }
    },
    callCount () {
      if (!this.calls || this.calls.length === 0) return 0
      let result = 0
      this.calls.forEach(item => {
        result += item.count
      })
      return result
    },
    filtersExist () {
      return this.$route.query && Object.keys(this.$route.query).length > 0
    },
    showPlaybookOverviewSummary () {
      return (this.checkFilter({ category: "playbooks" }) || this.checkFilter({ category: "objection" })) &&
        this.$route.query.playbook && this.selectedPlaybook && this.callCount > 0
    }
  },
  watch: {
    "$route.query" () {
      this.getData()
    },
    dateFilter: {
      handler (val) {
        const query = { ...this.$route.query }
        !val || !val.start ? delete (query.start_date) : query.start_date = val.start
        !val || !val.end ? delete (query.end_date) : query.end_date = val.end
        this.$router.push({ query }).catch(() => {
        })
      },
      deep: true
    },
    userFilter (val) {
      const query = { ...this.$route.query }
      if (!val || !val.email) {
        delete (query.user__email)
      } else {
        query.user__email = val.email
      }
      this.$router.push({ query })
    },
    counterpartFilter (val) {
      const query = { ...this.$route.query }
      if (!val || !val.name) {
        delete (query.counterparts__name)
      } else {
        query.counterparts__name = val.name
      }
      this.$router.push({ query })
    }
  },
  created () {
    this.setUp()
  },
  methods: {
    ...mapMutations({
      setError: "globalError/setError"
    }),
    setUp () {
      if (!this.$route.query || Object.keys(this.$route.query).length === 0) {
        const end = new Date()
        end.setHours(23, 59, 59, 999)
        const start = new Date()
        start.setDate(start.getDate() - 7)
        start.setHours(0, 0, 0, 0)
        this.dateFilter = { start: start.toISOString(), end: end.toISOString() }
        this.setFilter({ category: "calls", timeframe: "days" })
      } else {
        this.dateFilter = { start: this.$route.query.start_date, end: this.$route.query.end_date }
      }
      this.$nextTick(() => {
        this.setupComplete = true
        this.getData()
      })
    },
    answerAsFilter (text) {
      const newFilter = {}
      newFilter[this.$route.query.category] = text
      this.setFilter(newFilter)
    },
    getCorrectDateFormat (d) {
      return "" + d.getFullYear() +
        "-" + ("0" + (d.getMonth())).slice(-2) +
        "-" + ("0" + d.getDate()).slice(-2) +
        " " + ("0" + d.getHours()).slice(-2) +
        ":" + ("0" + d.getMinutes()).slice(-2)
    },
    resetFilter () {
      this.dateFilter = {}
      this.userFilter = null
      this.counterpartFilter = null
      this.$router.push({ query: {} })
    },
    tableLabel () {
      return this.chartDisplayStyle === "total" ? this.staticText.playbookLabel : this.staticText.dateLabel
    },
    getVariant (dictionary, trueVar = "secondary", falseVar = "outline-dark") {
      return this.checkFilter(dictionary) ? trueVar : falseVar
    },
    setFilter (filter) {
      const defaultFilter = { ...this.$route.query }
      for (const i in filter) {
        // if a filter already exists we remove it from the query
        if (defaultFilter[i] === filter[i] || filter[i] === null) delete (defaultFilter[i])
        else defaultFilter[i] = filter[i]
      }
      this.$router.push({ query: defaultFilter })
    },
    checkFilter (dictionary) {
      let result = true
      for (const [key, value] of Object.entries(dictionary)) {
        if (!value) continue
        const keyVal = this.$route.query[key]
        if (Array.isArray(value)) {
          result = value.some(item => this.compareValues(keyVal, item))
        } else {
          result = !!keyVal && this.compareValues(keyVal, value) // note: === not working here, probably due to type conversion
        }
        if (!result) return false
      }
      return true
    },
    showCallChart (filter) {
      // dont reset filter if the filters are already set correctly
      if (filter.type && this.$route.query.type && filter.type === this.$route.query.type) return
      if (filter.timeframe && this.$route.query.timeframe && filter.timeframe === this.$route.query.timeframe) return
      if (filter.type) filter.timeframe = null
      if (filter.timeframe) filter.type = null
      this.setFilter(filter)
    },
    setLoadingIndicators (indicator) {
      this.loading.push(indicator)
      return () => {
        this.$nextTick(() => this.removeLoading(indicator))
      }
    },
    isLoading (indicator) {
      return this.loading && this.loading.indexOf(indicator) >= 0
    },
    removeLoading (indicator) {
      const index = this.loading.indexOf(indicator)
      if (index !== -1) this.loading.splice(index, 1)
    },
    getData () {
      this.$nextTick(() => {
        this.loadPlaybooks()
        this.getFilteredCalls().then(() => {
          if (this.checkFilter(this.defaults.callsFilter)) {
            const timeframeLabel = this.chartDisplayStyle === "total"
              ? "Total"
              : this.timeframeSelection[this.chartDisplayStyle]
            this.chartTitle = this.staticText.conversationsLabel + "/" + timeframeLabel
            this.displayNewData(this.calls)
          }
        })
      })
    },
    compareValues (value1, value2) {
      if (typeof value1 === "string" || typeof value1 === "number") value1 = value1.toString()
      if (typeof value2 === "string" || typeof value2 === "number") value2 = value2.toString()
      return value1 === value2
    },
    selectPlaybook (playbook, index) {
      playbook.selected = true
      this.selectedPlaybook = playbook
      const queryPlaybookId = this.$route.query.playbook
      if (!this.compareValues(queryPlaybookId, playbook.id)) this.setFilter({ playbook: playbook.id, playbook_index: index })
    },
    getUrlFilter (withPlaybook = false) {
      const params = { ...this.url_filter }
      if (!withPlaybook) delete (params.playbook)
      return params
    },
    loadPlaybooks () {
      const that = this
      const removeFnc = this.setLoadingIndicators("loadingPlaybooks")
      const params = this.getUrlFilter()
      return this.axios.get(this.basePlaybookUrl, { params }).then(response => {
        that.playbooks = response.data
      }).then(removeFnc)
    },
    getFilteredCalls (filters = {}) {
      filters = this.getUrlFilter()
      this.setNewData()
      const removeFnc = this.setLoadingIndicators("loadingCalls")
      return new Promise((resolve) => {
        this.getFilteredData(this.baseCallUrl, filters).then(response => {
          if (this.chartDisplayStyle === "total") {
            this.calls = response
          } else {
            this.calls = response.map(item => {
              const timeFunc = this.dateFormatingOptions[this.chartDisplayStyle]
              item.text = timeFunc(new Date(item.timeframe))
              return item
            })
          }
          removeFnc()
          resolve(response)
        }).catch(error => {
          this.setError(error)
        })
      })
    },
    setNewData (data) {
      this.dataToDisplay = []
      if (data) {
        this.dataToDisplay = this.computeRelativeCount(data)
      }
    },
    displayNewData (data) {
      if (this.chartDisplayStyle === "total") {
        data = data.slice(0, 5)
      }
      this.setNewData(data)
    },
    getFilteredData (url, filters = {}) {
      return new Promise((resolve) => {
        this.axios.get(url, { params: filters }).then(
          response => {
            resolve(response.data)
          }).catch(error => {
          this.setError(error)
        })
      })
    },
    computeRelativeCount (answersList) {
      let totalAnswers = 0
      for (const i in answersList) {
        totalAnswers += answersList[i].count
      }
      answersList = answersList.map(answer => {
        answer.relativeCount = ((answer.count / totalAnswers) * 100).toFixed(2) + "%"
        return answer
      })
      return answersList
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.filter-button-opened-icon {
  padding: 10px 11px;
  color: $white;
  background-color: $orange;
  border-radius: 20px;
}

.filter-button-closed-icon {
  padding: 10px 11px;
  color: $slate;
  background-color: $white;
  border-radius: 20px;
}

.navigation-menu {
  background-color: $white40;
  border-radius: 22px;
}

.filter-button {
  padding: 0;
  margin: 12px 0 12px 24px;
  background: inherit;
  box-shadow: none;
}

.radio-button-on {
  color: $slate;
  border-radius: 50%;
  background-color: $white;
  padding: 4px;
  font-size: 7px;
}

.radio-button-on .fa {
  font-size: 7px;
}

.radio-button-off {
  color: $white;
  border-radius: 50%;
}

.current-answer {
  padding: 11px 14px;
  background-color: $white40;
  border-radius: 12px;
}

.current-answer-title {
  font-size: 12px;
  line-height: 14px;
  color: $slate40;
}

.trash-answer-btn {
  background-color: $slate06;
  height: 46px;
  width: 46px;
  border-radius: 12px;
}

.current-answer-result {
  color: $slate80;
  overflow-wrap: anywhere;
}

.analytics-item-selector {
  overflow-wrap: anywhere;
  display: flex;
  align-items: baseline;
}

</style>
