<template>
  <div class="d-flex justify-content-between align-items-center"
       :class="{'arc-pie': isFilter}"
       @click="$emit('filter', answer.text)"
       @mouseenter="hovered = true"
       @mouseleave="hovered = false"
  >
    <div class="d-flex align-items-center">
      <div class="m-1">
        <vc-donut v-bind="donutProps">
          {{ getInnerLabel }}
        </vc-donut>
      </div>

      <div v-if="answer"
           class="m-1"
      >
        <span v-if="!label">
          {{ answer.text }} ({{ answer.count }})
        </span>

        <span v-else>
          {{ label }}
        </span>
      </div>
    </div>

    <div v-if="isFilter"
         class="p-2 m-2 bordered"
         :class="{'bordered-hover': hovered}"
    >
      Set as filter
      <i class="fas fa-filter ml-1"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArcPie",
  props: {
    multi: {
      type: Array,
      default: null
    },
    answer: {
      type: Object,
      default: null
    },
    relField: {
      type: String,
      default: "rel_count"
    },
    label: {
      type: String,
      default: null
    },
    isFilter: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "#35375B"
    }
  },
  data () {
    return {
      hovered: false
    }
  },
  computed: {
    donutProps () {
      return {
        size: 70,
        sections: this.multi ? this.multi : [{ value: this.answer[this.relField] * 100, color: this.color }],
        thickness: 25, // set this to 100 to render it as a pie chart instead
        hasLegend: !this.answer,
        autoAdjustTextSize: false,
        legendPlacement: "right"
      }
    },
    getInnerLabel () {
      return this.multi || !this.answer ? "" : (this.answer[this.relField] * 100).toFixed(1) + "%"
    }
  }
}
</script>

<style scoped lang="scss">
.arc-pie {
  transition: all 0.5s;
  border-radius: 15px;
  border: 1px solid #0d5aa700;
  cursor: pointer;
  &:hover {
    border: 1px solid #007bff;
    background-color: #007bff11;
  }
}

.bordered {
  color: $white;
  transition: all 0.5s;
  opacity: 0;
  background-color: #007bff;
  border-radius: 25px;
}

.bordered-hover {
  opacity: 1;
}
</style>
