<template>
  <div>
    <div v-if="!noDataAvailableForFilter" :class="[!filterComplete && !isLoading && chartTitle ? '' : 'card']">
      <div v-if="filterComplete && !isLoading && chartTitle"
           class="mb-2 d-flex justify-content-between"
      >
        <h5 class="ml-2 subTitle">
          {{chartTitle}}
        </h5>

        <h6 class="mt-auto font-size-14 pr-4 mr-1">
          {{staticText.noPeriodNoConversationMsg}}
        </h6>
      </div>
      <!-- Chart for the table element -->
      <bao-analytics-chart id="dashboard-chart-202012291444"
                           :filtered-data="dataToDisplay"
                           :display-style="chartDisplayStyle"
      ></bao-analytics-chart>
    </div>

    <div>
      <!-- No data messages -->
      <div v-if="noDataAvailableForFilter" class="no-filter-selected">
        <div class="w-50 text-center">
          <i class="fas fa-search no-filter-selected-icon"></i>
          <h5 class="font-weight-bold">{{staticText.noFilterSelectedTitle}}</h5>
          <h6>{{staticText.noFilterSelectedText}}</h6>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <p>{{staticText.displayMsg}}</p>
        </div>
      </div>

      <div v-else-if="dataAvailableForFilter">
        <!-- Main table element -->
        <b-table
          id="dashboard-table-202012291505"
          :items="dataToDisplay"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-direction="sortDirection"
          :sort-desc.sync="sortDesc"
          :busy="isLoading"
          class="mt-3"
        >
          <template v-slot:table-busy>
            <progress-indicator></progress-indicator>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="showFilterBtn"
                 class="text-center text-primary my-2"
            >
              <b-btn variant="primary"
                     @click="$emit('set-filter', data.item.text)"
              >
                {{staticText.setFilterBtnLabel}}
              </b-btn>
            </div>
          </template>
        </b-table>
      </div>

      <div v-else-if="isLoading">
        <progress-indicator></progress-indicator>
      </div>
    </div>

    <div v-if="!filterComplete && !isLoading" class="no-filter-selected">
      <div class="w-50 text-center">
        <i class="fas fa-search no-filter-selected-icon"></i>
        <h5 class="font-weight-bold">{{staticText.noFilterSelectedTitle}}</h5>
        <h6>{{staticText.noFilterSelectedText}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProgressIndicator from "../../base/ProgressIndicator"
import BaoAnalyticsChart from "./BaoAnalyticsChart"

export default {
  name: "BaoAnalyticsCallsChart",
  components: {
    BaoAnalyticsChart,
    ProgressIndicator
  },
  props: {
    dataToDisplay: {
      type: Array,
      required: true
    },
    chartDisplayStyle: {
      type: String,
      required: true
    },
    loading: {
      type: Array,
      required: true
    },
    tableLabel: {
      type: String,
      required: true
    },
    showFilterBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    chartTitle: {
      type: String,
      default: "",
      required: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        setFilterBtnLabel: "Set as filter",
        countLabel: "Count",
        relativeCountLabel: "Relative Count",
        actionLabel: "Actions",
        noPeriodNoConversationMsg: "Note: periods without conversations not displayed",
        noRecordsMsg: "There are no records to show for the selected filters.",
        displayMsg: "Please select the data to display from the left.",
        noFilterSelectedTitle: "No Analytics to Display",
        noFilterSelectedText: "Your selected filter is not set or incomplete, please select correct filter parameters from the menu to the left."
      },
      sortBy: "count",
      sortDesc: true,
      sortDirection: "desc"
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    isLoading () {
      return !!this.loading && this.loading.length > 0
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    urlFilter () {
      return { ...this.$route.query }
    },
    filterComplete () {
      if (!(Object.keys(this.urlFilter).length > 0 && !!this.urlFilter.category)) return false
      const filterCriteria = {
        answers: ["playbook", "item"],
        objections: ["playbook"]
      }
      for (const i in filterCriteria) {
        if (this.urlFilter.category === i && !filterCriteria[i].every(item => !!this.urlFilter[item])) {
          return false
        }
      }
      return Object.keys(this.urlFilter).length > 0 && !!this.urlFilter.category
    },
    fields () {
      return [
        {
          key: "text",
          label: this.tableLabel,
          sortable: false
        },
        {
          key: "count",
          label: this.staticText.countLabel,
          sortable: true
        },
        {
          key: "relativeCount",
          label: this.staticText.relativeCountLabel,
          sortable: true
        },
        {
          key: "actions",
          label: this.staticText.actionLabel,
          sortable: false
        }
      ]
    },
    noDataAvailableForFilter () {
      return this.filterComplete && !this.isLoading && (!this.dataToDisplay || this.dataToDisplay.length === 0)
    },
    dataAvailableForFilter () {
      return this.filterComplete && !this.isLoading && !!this.dataToDisplay && this.dataToDisplay.length > 0
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  padding: 34px 12px  24px 40px;
  border-radius: 20px;
}

.no-filter-selected {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $slate;
}

.no-filter-selected-icon {
  font-size: 24px;
  padding: 29px;
  margin-bottom: 24px;
  width: 82px;
  height: 82px;
  color: $slate40;
  background-color: $slate05;
  border-radius: 50%;
}
</style>
