<template>
  <div>
    <progress-indicator v-show="loading"></progress-indicator>

    <div v-if="showPlaybookOverviewSummary">
      <div class="d-flex justify-content-between mb-2">
        <h4 class="my-auto overflow-wrap-anywhere">
          {{ displayPlaybook.name }}
        </h4>

        <div class="d-flex align-items-center">
          <csv-provider :playbookName="displayPlaybook.name"
                        :callFlow="displayPlaybook.call_flow"
          ></csv-provider>

          <b-btn variant="secondary"
                 @click="resetMaxNumber"
                 class="px-4 d-flex align-items-center"
          >
            <img src="../../../../public/img/icons/view-icon.svg" class="mb-1 pr-1"/>
            {{ getButtonLabel }}
          </b-btn>
        </div>
      </div>

      <!-- Playbook Stats Summary - TODO: refactor into separate component -->
      <b-table :fields="getFields"
               class="animated"
               :items="displayPlaybook.call_flow"
      >
        <!-- Header: Playbook Item Name-->
        <template v-slot:head(name)="data">
          <div class="d-flex">
            <div class="my-auto">
              <div :id="'help-btn' + data.label"
                   class="mr-1"
              >
                <i class="far fa-question-circle"></i>
              </div>
              <b-tooltip :target="'help-btn' + data.label">
                {{ staticText.helpTextName }}
              </b-tooltip>
            </div>
            <div>
              {{ data.label }}
            </div>
          </div>
        </template>

        <!-- Header: Answers and Loaded Playbooks Statistics -->
        <template v-slot:head(answers)="data">
          <div class="d-flex">
            <div class="my-auto">
              <div :id="'help-btn' + data.label"
                   class="mr-2"
              >
                <i class="far fa-question-circle"></i>
              </div>

              <b-tooltip :target="'help-btn' + data.label"
                         placement="left"
              >
                {{ staticText.helpTextAnswers }}
              </b-tooltip>
            </div>
            <div>
              {{ data.label }}
            </div>
          </div>
        </template>

        <!-- Header: Shortcuts -->
        <template v-slot:head(objections)="data">
          <div class="d-flex">

            <div class="my-auto">
              <div :id="'help-btn' + data.label"
                   class="mr-1"
              >
                <i class="far fa-question-circle"></i>
              </div>
              <b-tooltip :target="'help-btn' + data.label"
                         placement="left"
              >
                {{ staticText.helpTextObjections }}
              </b-tooltip>
            </div>
            <div>
              {{ data.label }}
            </div>
          </div>
        </template>

        <!-- Cell: Playbook Item Name + Arc Pies -->
        <template v-slot:cell(name)="data">
          <div class="mb-2">
            {{ data.item.name }}
          </div>

          <!-- Answer count arc pie -->
          <arc-pie v-if="data.item.rel_answer && data.item.rel_answer > 0"
                   :answer="data.item"
                   :is-filter="false"
                   rel-field="rel_answer"
                   label="Answers"
          ></arc-pie>

          <!-- Objection count arc pie -->
          <arc-pie v-if="data.item.rel_objection && data.item.rel_objection > 0"
                   :answer="data.item"
                   :is-filter="false"
                   rel-field="rel_objection"
                   color="#ff2956"
                   label="Shortcuts"
          ></arc-pie>
          <!-- row details toggle button -->
          <b-button v-if="Object.values(data.item.transcripts).some(item => item !== null)"
                    size="sm"
                    class="mt-1"
                    @click="data.toggleDetails"
          >
            {{ data.detailsShowing ? staticText.hideDetailsText : staticText.showDetailsText }}
          </b-button>

        </template>
        <!-- row details data (transcript data) -->
        <template #row-details="data">

          <b-card>
            <div v-if="Object.values(data.item.transcripts).some(item => item !== null)"
                 class="row mr-auto-l"
            >
              <real-time-assistant-arc-pie
                v-if="isValidNumber(data.item.transcripts.talking_share__avg)"
                label-placement="bottom"
                :label="staticText.talkingShareLabel"
                :value="data.item.transcripts.talking_share__avg"
              ></real-time-assistant-arc-pie>

              <real-time-assistant-arc-pie
                v-if="isValidNumber(data.item.transcripts.talking_speed__avg)"
                label-placement="bottom"
                :total="220"
                :label="staticText.talkingSpeedLabel"
                :value="data.item.transcripts.talking_speed__avg"
                :inner-text="'' + data.item.transcripts.talking_speed__avg.toFixed(0)"
              ></real-time-assistant-arc-pie>

              <real-time-assistant-arc-pie
                v-if="showTextMatch && isValidNumber(data.item.transcripts.text_similarity__avg)"
                label-placement="bottom"
                :label="staticText.textSimilarityLabel"
                :value="data.item.transcripts.text_similarity__avg"
              ></real-time-assistant-arc-pie>
            </div>

          </b-card>
        </template>

        <template v-slot:cell(answers)="data">

          <!-- Answer Bar Chart -->
          <div v-if="data.item.answers && data.item.answers.length > 0"
               :style="'height: ' + getHeight(data.item.answers.slice(0, maxNumber).length) + 'px'"
               class="p-2"
          >
            <horizontal-bar-chart
              :chartData="getTablePieChartData(data.item.answers, data.item.name, 'rel_count', data.item.rel_answer)"
              :style="chartStyle"
              :chart-options="getChartOptions(true)"
              :height="getHeight(data.item.answers.slice(0, maxNumber).length)"
              :chart="{addLabel:'%'}"
              @filter="(value) => setFilter({answers: value})"
            ></horizontal-bar-chart>
          </div>

          <div v-if="data.item.add_playbooks && data.item.add_playbooks.length > 0">
            {{ staticText.addedPlaybooksLabel }}
            <horizontal-bar-chart
              :chartData="getTablePieChartData(data.item.add_playbooks, data.item.name, 'rel_count')"
              :style="chartStyle"
              :chart-options="getChartOptions(false)"
              :height="getHeight(data.item.add_playbooks.slice(0, maxNumber).length)"
              :click-to-filter="false"
              @filter="(value) => setFilter({answers: value})"
            ></horizontal-bar-chart>
          </div>
        </template>

        <!-- Shortcuts Cell -->
        <template v-slot:cell(objections)="data">
          <div v-if="data.item.objections && data.item.objections.length > 0">
            <b-table :items="data.item.objections.slice(0, maxNumber)"
                     :fields="getObjectionFields"
                     :tbody-tr-class="getRowClass"
                     thead-class="hidden_header"
                     hover
                     borderless
                     @row-clicked="handleRowSelect"
            >
              <template v-slot:cell(rel_count)="data">
                {{ (data.item.rel_count * 100).toFixed(2) }} %
              </template>
            </b-table>
          </div>
        </template>
      </b-table>
    </div>

    <!-- in case there is no data available -->
    <div v-else-if="!loading">
      <div class="d-flex justify-content-center align-items-center">
        <p>
          {{ staticText.noRecordsMsg }}
        </p>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <p>
          {{ staticText.displayMsg }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import ArcPie from "../ArcPie"
import ProgressIndicator from "../../base/ProgressIndicator"
import CsvProvider from "./BaoAnalyticsCsvProvider"
import RealTimeAssistantArcPie from "@/apps/call/RealTimeAssistantComponents/RealTimeAssistantArcPie"
import { mapGetters } from "vuex"
import { getDefaultHorizontalBarChartOptions } from "@/apps/dashboard/chartUtils"

export default {
  name: "PlaybookOverviewSummary",
  components: {
    HorizontalBarChart: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "HorizontalBarChart" */ "../BaoWidgetsBaseComponents/HorizontalBarChart"),
    ProgressIndicator,
    ArcPie,
    RealTimeAssistantArcPie,
    CsvProvider
  },
  data () {
    return {
      axios,
      displayPlaybook: null,
      staticTextDefault: {
        answersLabel: "Answers",
        shortcutsLabel: "Shortcuts",
        helpTextName: "Proportion of conversations, in which either an answer or an objection of this item was clicked",
        helpTextAnswers: "Proportion of answers, if the item was covered. Hover the graph to see the total count, " +
            "then click to filter.",
        helpTextObjections: "Name, total count and proportion of conversations, in which the corresponding objection " +
            "was clicked. Click an objection to filter.",
        showAllLabel: "Show all",
        showTop5Label: "Show Top 5",
        noRecordsMsg: "There are no records to show for the selected filters.",
        displayMsg: "Please select the data to display from the left.",
        addedPlaybooksLabel: "Added Playbooks:",
        hideDetailsText: "Hide Details",
        showDetailsText: "Show Details",
        talkingShareLabel: "Talking Share",
        talkingSpeedLabel: "Talking Speed (WPM)",
        textSimilarityLabel: "Text-Match"
      },
      percentageBased: true,
      loading: false,
      maxNumber: 5
    }
  },
  computed: {
    ...mapGetters({ showTextMatch: "auth/showTextMatch" }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getFields () {
      return [
        {
          key: "name",
          sortable: true,
          class: "mwpx-250"
        },
        {
          key: "answers",
          label: this.staticText.answersLabel
        },
        {
          key: "objections",
          label: this.staticText.shortcutsLabel,
          class: "min-width-350"
        }
      ]
    },
    getObjectionFields () {
      return ["text", "count", "rel_count"]
    },
    urlFilter () {
      return { ...this.$route.query }
    },
    chartStyle () {
      return {
        width: `${100}%`,
        height: `${100}%`,
        position: "relative"
      }
    },
    showPlaybookOverviewSummary () {
      return !this.loading && this.displayPlaybook && this.displayPlaybook.call_flow &&
          this.displayPlaybook.call_flow.length > 0
    },
    getButtonLabel () {
      return this.maxNumber === 5 ? this.staticText.showAllLabel : this.staticText.showTop5Label
    }
  },
  watch: {
    "$route.query" () {
      this.getData()
    }
  },
  mounted () {
    if (this.urlFilter.playbook) {
      this.getData()
    }
  },
  methods: {
    getChartOptions (clickToFilter = false) {
      const that = this
      // Tooltip is not needed on other components where the horizontal chart is used, so we have to
      // enable it here. For the analytics page, there's a click event and behavior set here few lines
      // beneath, so we disable the default chart click behavior
      const configOptions = { showTooltip: true, disableChartClickBehavior: true }
      const options = getDefaultHorizontalBarChartOptions({ addLabel: "%" }, this, configOptions)
      if (clickToFilter) {
        options.onClick = function (event, data) {
          if (data && data.length > 0 && data[0]._model) {
            that.setFilter({ answers: data[0]._model.label })
          }
        }
        options.onHover = function (e) {
          const point = this.getElementAtEvent(e)
          if (point.length) e.target.style.cursor = "pointer"
          else e.target.style.cursor = "default"
        }
      }
      return options
    },
    resetMaxNumber () {
      if (this.maxNumber === 5) {
        this.maxNumber = 1000
      } else {
        this.maxNumber = 5
      }
      this.$forceUpdate()
    },
    getRowClass (item) {
      let result = "point-on-hover"
      if (this.$route.query && this.$route.query.objections && this.$route.query.objections === item.text) {
        result += " table-primary"
      }
      return result
    },
    handleRowSelect (item) {
      if (this.$route.query && this.$route.query.objections && this.$route.query.objections === item.text) {
        this.setFilter({ objections: null })
      } else {
        this.setFilter({ objections: item.text })
      }
    },
    getHeight (length) {
      const factor = 40
      return (length + 1) * factor
    },
    setFilter (filter) {
      const defaultFilter = { ...this.$route.query }
      for (const i in filter) {
        // if a filter already exists we remove it from the query
        if (defaultFilter[i] === filter[i] || filter[i] === null) delete (defaultFilter[i])
        else defaultFilter[i] = filter[i]
      }
      this.$router.push({ query: defaultFilter })
    },
    getData () {
      this.loading = true
      return this.axios.get("/api/talkscriptstatistics/" + this.urlFilter.playbook, { params: this.urlFilter })
        .then(response => {
          this.displayPlaybook = response.data
          this.loading = false
        })
    },
    getTablePieChartData (items, name, field = "count", relAnswer = 1) {
      const array = items.slice(0, this.maxNumber)
      return {
        labels: array.map(item => item.text),
        datasets: [
          {
            label: name,
            data: array.map(item => field === "count" ? item[field] : (item[field] / relAnswer * 100).toPrecision(2)),
            absolut: array.map(item => item.count),
            backgroundColor: "#35375B",
            borderColor: "#35375B",
            borderWidth: 1
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
