<template>
  <b-btn class="px-4 mr-2 d-flex align-items-center"
         variant="secondary"
         @click="downloadCSV"
  >
    <i class="fas fa-file-csv mb-1 pr-1"></i>
    {{ staticText.downloadCSV }}
  </b-btn>
</template>

<script>
export default {
  name: "CsvProvider",
  props: {
    playbookName: {
      type: String,
      required: true
    },
    callFlow: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        downloadCSV: "Download CSV"
      },
      defaults: {
        typeMappings: {
          answers: "Answer",
          objections: "Objection",
          add_playbooks: "Dyn_Playbook"
        },
        dataHeader: [
          "item_name",
          "rel_answer",
          "rel_objection",
          "text",
          "count",
          "rel_count",
          "type"
        ]
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    getDataHeader () {
      return `${this.defaults.dataHeader.join(";")}\r\n`
    },
    getItemTypesToIterate () {
      return Object.keys(this.defaults.typeMappings)
    },
    getColsFromData (item, itemType, itemTypeEntry) {
      return [
        item.name,
        item.rel_answer,
        item.rel_objection,
        itemTypeEntry.text,
        itemTypeEntry.count,
        itemTypeEntry.rel_count,
        this.defaults.typeMappings[itemType]
      ]
    },
    createCSVRows (item, itemType) {
      let csvRows = ""
      const entriesForItemType = item[itemType]
      entriesForItemType.forEach((entry) => {
        csvRows += `${this.getColsFromData(item, itemType, entry).join(";")}\r\n`
      })
      return csvRows
    },
    getContent () {
      let finalCSV = this.getDataHeader()
      const itemTypesToIterate = this.getItemTypesToIterate()

      this.callFlow.forEach((item) => {
        itemTypesToIterate.forEach((itemType) => {
          finalCSV += this.createCSVRows(item, itemType)
        })
      })

      return finalCSV
    },
    downloadCSV () {
      const finalCSV = this.getContent()
      const link = document.createElement("a")
      const blob = new Blob([finalCSV], { type: "text/csv" })
      const csvUrl = window.webkitURL.createObjectURL(blob)
      link.setAttribute("download", `${this.playbookName}.csv`)
      link.setAttribute("href", csvUrl)
      link.click()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
