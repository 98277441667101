<template>
  <div>
    <div id="chart"
         v-if="!!chartData"
    >
      <bar v-show="displayStyle === 'total'"
           :chart-data="chartData"
           :options="chartOptions"
           :styles="myStyles"
      ></bar>

      <line-chart v-show="displayStyle !== 'total'"
                  :chart-data="chartData"
                  :chart-options="chartOptions"
                  :styles="myStyles"
      ></line-chart>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "BaoAnalyticsChart",
  components: {
    LineChart: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "LineChart" */ "../BaoWidgetsBaseComponents/LineChart"),
    Bar: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "BarChart" */ "../BaoWidgetsBaseComponents/BarChart.js")
  },
  props: {
    filteredData: {
      type: Array,
      default: null
    },
    displayStyle: {
      type: String
    }
  },
  data () {
    return {
      chartData: null,
      chartOptions: {},
      maxCount: 0
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    myStyles () {
      return {
        width: `${100}%`,
        height: `${window.innerWidth / 5}px`,
        position: "relative"
      }
    }
  },
  watch: {
    filteredData: {
      handler: function () {
        this.chartSetup()
      }
    }
  },
  methods: {
    wordWrap (s, w) {
      if (!s) {
        return ""
      }
      const splitChar = "{{cut}}"
      return s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"), "$1" + splitChar).split(splitChar)
    },
    getDefaultBarChartOptions () {
      const that = this
      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 4,
              max: this.maxCount
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 60,
            maxBarThickness: 60,
            minBarLength: 2,
            gridLines: {
              display: false
            },
            ticks: {
              callback: function (label) {
                return that.wordWrap(label, 30)
              }
            }
          }]
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function (tooltipitem) {
              tooltipitem = tooltipitem[0]
              return tooltipitem.xLabel
            }
          }
        }
      }
    },
    getDefaultLineChartOptions () {
      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 4
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 60,
            maxBarThickness: 60,
            minBarLength: 2,
            gridLines: {
              display: false
            }
          }]
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        },
        animation: {
          duration: 0 // general animation time
        },
        hover: {
          animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
      }
    },
    chartSetup () {
      this.chartData = null
      if (this.filteredData.length === 0) {
        return
      }
      this.chartData = this.transformData(this.filteredData)
      this.chartOptions = this.displayStyle === "total"
        ? this.getDefaultBarChartOptions()
        : this.getDefaultLineChartOptions()
    },
    getTimeLabel (item, displayStyle) {
      let timeLabel = new Date(item.timeframe)
      let language = this.user.language
      if (language === "en") language = "en-GB"
      if (displayStyle === "months") {
        timeLabel = timeLabel.toLocaleString(language, { month: "long" })
      }
      if (displayStyle === "weeks") {
        timeLabel = timeLabel.toLocaleString(language, { month: "short", day: "numeric", year: "numeric" })
      }
      if (displayStyle === "days") {
        timeLabel = timeLabel.toLocaleString(language, { month: "numeric", day: "numeric", year: "numeric" })
      }
      return timeLabel
    },
    transformData (baseData) {
      const firstItem = baseData[0]
      const newChartData = {}
      if (this.displayStyle === "total") {
        this.maxCount = firstItem.count
        newChartData.labels = baseData.map(item => item.text)
        newChartData.datasets = [{
          data: this.filteredData.map(item => item.count),
          backgroundColor: "rgba(177, 82, 36, 0.08)",
          borderColor: "rgba(177, 82, 36, 0.4)",
          borderWidth: 2
        }]
      } else {
        newChartData.labels = baseData.map(item => {
          return this.getTimeLabel(item, this.displayStyle)
        })
        newChartData.datasets = [{
          data: this.filteredData.map(item => item.count),
          backgroundColor: "rgba(177, 82, 36, 0.08)",
          borderColor: "rgba(177, 82, 36, 0.4)",
          borderWidth: 2
        }]
      }
      return newChartData
    }
  }
}
</script>

<style scoped lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>
